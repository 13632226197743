import React from 'react'
import App from 'next/app'
import Head from 'next/head'
import { ApolloProvider } from '@apollo/react-hooks'
import { ApolloClient } from 'apollo-client'
import { ApolloLink, from } from 'apollo-link'
import { HttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { onError } from '@apollo/client/link/error'
import withApollo from 'next-with-apollo'
import { CookiesProvider } from 'react-cookie'
import * as ls from 'local-storage'
import { UserContextProvider } from '@catamaran/utils'
import * as Sentry from '@sentry/node'

import '@catamaran/styles/normalize.css'
import '@catamaran/styles/global.css'
import 'react-activity/dist/react-activity.css'

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  })
}

const setHeaders = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      Authorization: ls.get('accessToken'),
    },
  })

  return forward(operation)
})

const withData = withApollo(
  ({ initialState }) =>
    new ApolloClient({
      link: from([
        setHeaders,
        new HttpLink({
          uri: process.env.NEXT_PUBLIC_API_URL,
        }),
      ]),
      cache: new InMemoryCache().restore(initialState || {}),
    }),
)

class CatamaranOrientation extends App<any> {
  render() {
    const { Component, pageProps, apollo } = this.props
    return (
      <ApolloProvider client={apollo}>
        <CookiesProvider>
          <UserContextProvider>
            <Head>
              <link rel="stylesheet" href="https://rsms.me/inter/inter.css" />
              <link rel="shortcut icon" href="/favicon.ico?v=2" />
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1.0, viewport-fit=cover"
              />
              <title>Catamaran Orientation</title>
            </Head>
            <Component {...pageProps} />
          </UserContextProvider>
        </CookiesProvider>
      </ApolloProvider>
    )
  }
}

export default withData(CatamaranOrientation)
